<template>
  <div id="app">
    <h1 class="hidden-print">选择日期，计算天数</h1>
    <Main />
  </div>
</template>

<script>
import Main from './components/Main.vue'

import 'veui-theme-dls/common.less'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
body {
  max-height: 100vh;
}

#app {
  margin: 1em;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
