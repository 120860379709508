import Vue from 'vue'
import { $toast, $confirm } from 'veui'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use($toast)
Vue.use($confirm)

new Vue({
  render: h => h(App),
}).$mount('#app')
