<template>
  <div class="count-dates">
    <p class="option-panel hidden-print">
      <span class="option-div">开始日期：
        <veui-date-picker v-model="startDate" />
      </span>
      <span class="option-div">显示面板数：
        <veui-number-input v-model="panelCount" :min="1" :max="99" :step="1" placeholder="2"/>
      </span>
    </p>
    <veui-calendar
      ref="calendar"
      :key="calendarKey"
      v-model="ranges"
      range
      multiple
      :panel="panelCount ?? 2"
      :today="startDate"
    />
    <div class="result-panel">
      <p>总天数:
        <strong>{{ totalCount }}</strong>
        <span>。已选择日期：</span>
        <span class="date-tag" v-for="date in dates" :key="date.text">{{ date.text }} ({{ date.count }}天)</span>
      </p>
      <p>
        <veui-button ui="xs primary" :disabled="!ranges.length" @click="handleSaveButtonClick">保存</veui-button>
        &nbsp;&nbsp;
        <veui-button ui="xs text" :disabled="!ranges.length" @click="handleClearButtonClick">清空</veui-button>
      </p>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import { Calendar, DatePicker, NumberInput, Button } from 'veui';

const SAVE_KEY = 'cn.hk1229.count-dates.save';

export default {
  name: 'CountDates',
  components: {
    'veui-calendar': Calendar,
    'veui-date-picker': DatePicker,
    'veui-number-input': NumberInput,
    'veui-button': Button,
  },
  data() {
    return {
      startDate: new Date,
      panelCount: 2,
      ranges: []
    };
  },
  computed: {
    calendarKey() {
      return this.startDate.toISOString() + this.panelCount + (
        this.ranges.length? 'Y' : 'N'
      );
    },
    dates() {
      return this.ranges.map(range => {
        const text = formatDateRange(...range);
        const count = differenceInDays(range[1], range[0]) + 1;
        return {text, count};
      });
    },
    totalCount() {
      return this.dates.reduce((sum, { count }) => sum + count, 0);
    }
  },
  methods: {
    handleSaveButtonClick() {
      localStorage.setItem(SAVE_KEY, JSON.stringify({startDate: this.startDate, panelCount: this.panelCount, ranges: this.ranges}));
      this.$toast.success('保存成功');
    },
    async handleClearButtonClick() {
      if (!(await this.$confirm('确定清空已选日期？'))) return;
      this.ranges = [];
    }
  },
  mounted() {
    try {
      const {startDate, panelCount, ranges} = JSON.parse(localStorage.getItem(SAVE_KEY));
      if (!ranges) return;
      this.startDate = new Date(startDate);
      this.panelCount = panelCount;
      this.ranges = ranges.map(range => [new Date(range[0]), new Date(range[1])]);
    }
    catch (e) {
      console.error(e);
    }
  },
}

function formatDateRange(a, b) {
  const aStr = formatDate(a);
  const bStr = formatDate(b);
  return aStr === bStr ? aStr : aStr +'~'+ bStr;
}
function formatDate(date) {
  return format(date, 'MM/dd');
}
</script>

<style scoped>
.count-dates >>> .veui-calendar-panels {
  flex-wrap: wrap;
}

.date-tag {
  display: inline-block;
  border: 1px solid gray;
  padding: 0 4px;
  margin-right: 8px;
  border-radius: 4px;
  background: #f6f6f6;
  margin-bottom: 4px
}

.result-panel {
  border: 1px solid #f48412;
  margin: 1em 0;
  padding: 0 1em;
}

.option-panel {
  margin-bottom: 1em;
}
.option-div + .option-div {
  margin-left: 1em;
}

@media screen {
  .result-panel {
    background: linear-gradient(180deg, #ffffffbb 10%, #ffffffee 30%);
    max-height: 180px;
    overflow: auto;

    position: sticky;
    bottom: 0;
  }
}

</style>
